<template>
  <div>
    <div class="des">共{{total}}条检查记录需要整改</div>
    <div style="padding-bottom: 50px;">
      <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
      >
        <div class="check-list" v-for="(item,index) in list" :key="index" >
          <div class="item-choose">
             {{index+1}}
          </div>

          <div class="item-info">
            {{parseCheckType(item.type)}}
          </div>
          <div class="item-check">
<!--            <div class="item-user"><van-icon name="user-o" size="14" />{{item.remark?item.remark:"&#45;&#45;"}}</div>-->
            <div style="font-size: 0.6rem;">{{item.createTime?item.createTime.substr(0,16):"--"}}</div>
          </div>
          <div class="item-btn" @click="viewDetail(item)">
            进入整改
            <span class="item-click"><van-icon name="arrow" /></span>
          </div>

        </div>

      </van-list>
      <!--    整改纪录闭环弹框-->
      <van-popup v-model="recordPopup" position="right" :style="{ height: '100%',width:'100%' }">
        <div class="model-title align-center">
          <van-icon name="arrow-left" size="28" @click="recordPopup=false" />
          <span style="position:relative;top: -5px;left: 10px;">详情</span>

        </div>
        <detail :recordData="recordData" :dict="dict" ref="record"></detail>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  List,Cell,Col, Row,Icon,Popup,Checkbox,Button
} from 'vant';
import detail from "./qyRectify"
export default {
  name: "item",
  components:{
    detail,
     [Button.name]: Button,
     [List.name]: List,
     [Row.name]: Row,
     [Col.name]: Col,
     [Popup.name]: Popup,
     [Cell.name]: Cell,
     [Checkbox.name]: Checkbox,
     [Icon .name]: Icon
  },
  computed:{
    tagId:function (){
      return this.getTagId()
    },
    listShow:function (){
      return Object.keys(this.recordData).length>0?true:false
    }
  },
  data(){
    return {
      dict:{},
      recordData:{},
      recordId:"",
      params:{
        size: 2,
        page: 0,
        checkType: "0",
        infoId:localStorage.getItem("wljptinfoId")
      },
      finished:false,
      loading:false,
      error:false,
      list:[],
      total:0,
      recordPopup:false,
      checkTable:{}
    }
  },
  methods:{
    // 获取所有检查表
    getCheckTables(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check/table/raw",
          method: 'get'
        }).then(result => {
          if(result.data.success){
            result.data.data.map(item=>{
              if(!this.checkTable[item.id]){
                this.checkTable[item.id] = item;
              }
            })
            resolve(true);
          }

        })
      })
    },
    getTagId(){
      let url = window.location.href;
      let search = url.split("?")[1];
      let reg = new RegExp("(^|&)tagId=([^&]*)(&|$)", "i");
      if(search){
        let r = search.match(reg);
        if(r !=null){
          return unescape(r[2])
        }
        return null
      }
    },
    viewDetail(item){
      if(item.id){
        this.dict = this.checkTable[item.type].typeList;
        this.$http({
          url: "/zuul/cultural/check/" + item.id,
          method: "get"
        }).then(res=>{
          if(res.data.success){
            this.recordData = res.data.data;
          }
        }).finally(f=>{
          this.recordPopup = true;
        })
      }
    },
    onLoad(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{
        if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
      })
    },
    getOnLoadList(){
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check?status=1",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);
        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
        this.refreshing = false;
      }
    },
    // 获取需要整改检查记录
    getList() {
      this.finished =false;
      this.loading = true;
      let _this = this;
      this.list = [];
      this.$http({
        url: "/zuul/cultural/check?status=1",
        method: "get",
        // params: this.params
        params: {
          ...this.params
        }
      }).then(res => {
        // console.log(res)
        if (res.data.success && res.data.data) {
          _this.list = res.data.data.results;
          _this.total = res.data.data.total;

        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response => {
        this.loading = false;
      })
    },
    // 解析检查类型
    parseCheckType(id){
      let str = "其他检查";
      if(this.checkTable[id]){
        switch (this.checkTable[id]["type"]){
          case "aqsc":
            str = "行业检查";
            break;
          case "yqfk":
            str = "疫情防控检查";
            break;
          case "wmcs":
            str = "文明城市创建检查";
            break;
          default:
            str = this.checkTable[id].name;
            break;
        }
      }
      return str;
    }
  },
  mounted(){
    this.params.infoId = localStorage.getItem("wljptinfoId");
    Promise.all([this.getCheckTables()]).then(res=>{
      if(this.params.infoId){
        this.getList();
      }
    })
  }
}
</script>

<style scoped lang="less">
  .des{
    padding: 10px 20px;
    color: rgba(69, 90, 100, 0.6);
    background: #f7f8fa;
  }
  .model-title{
    height: 50px;
    line-height: 50px;
  }
  .check-list{
    padding: 10px 20px 10px;
    font-size: 0.8rem;
    border-bottom: 1px solid #ebedf0;;
    >div{
      display: inline-block;
    }
    .item-choose{
      position: relative;
      width: 30px;
      vertical-align: middle;
      text-align: left;
    }

    .item-check{
      width: 100px;
      line-height: 1.2rem;
      vertical-align: middle;
      .item-user{
        padding: 5px 0px 2px 0px;
        .van-icon{
          position: relative;
          top: 2px;
          margin-right: 2px;
        }
      }
    }
    .item-btn{
      width: 100px;
      text-align:right;
      position: relative;
      .item-click{
        position: absolute;
        top: 2px;
        right: -20px;
        width: 30px;
        font-size: 1rem;
        color: #999;
      }
    }
    .item-check-res,.item-rectify{
      padding: 0 2px;
      vertical-align: middle;
      word-break: break-word;
      width: calc(100vw - 290px);
    }
    .item-check-res{
      position: relative;
      padding-left: 10px;

      //color: green;
      //cursor: pointer;
    }
    .item-info{
      width: calc(100vw - 300px);
      position: relative;
      left: -10px;
      vertical-align: middle;
      text-align: left;
    }

  }
</style>
