<template>
  <div class="detail">
    <div style="position:fixed;top: 50px;left: 0px;width: calc(100vw);z-index: 2;">
      <van-notice-bar
          color="#1989fa" background="#ecf9ff"
          wrapable
          left-icon="info-o" :scrollable="false"
          :text="'此次检查共'+recordData.problemList.length + '项需要整改，检查时间:'+recordData.createTime + (isRectify?'':',已提交整改。')"
      />
      <a v-if="!isRectify" href="javascript:void(0);" @click="isRectify=true;" style="position:absolute;right: 25px;bottom: 10px;font-size: 13px;color: #ed6a0c;">重新整改</a>
    </div>
    <div style="height: 40px;"></div>
    <div v-if="formData.check && formData.check.des" >
      <div class="section-title" >
        整改要求
      </div>
      <div style="padding:8px 0px 24px 24px;">{{formData.check?formData.check.des:""}}</div>
    </div>

    <div class="section-title">
      检查情况
    </div>
    <div class="form-title" v-for="(o,k,ind) in group" v-bind:key="'group'+ind">
      <div v-if="k!='根据机位设置情况抽查实名登记上网执行情况'">{{k}}</div>
      <div v-if="k!='根据机位设置情况抽查实名登记上网执行情况'">
        <div v-for="(obj, index) in o" v-bind:key="'dict'+ index" class="item" :class="{'hasRectify':(formData[obj.id] && formData[obj.id]['value']=='0')?true:false}">
          <div class="item-title">
            <span class="item-index">{{(index+1)}}</span>
            <span class="item-name">{{obj.name}}</span>
            <div class="item-check"  v-if="formData[obj.id].type=='radio'">
              <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                <van-radio name="1" icon-size="20px">是</van-radio>
                <van-radio name="0" icon-size="20px">否</van-radio>
              </van-radio-group>
            </div>

            <div class="item-check" v-if="formData[obj.id].type=='radio1'">
              <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                <van-radio icon-size="24px" v-for='(o,i) in tableItems[obj.id]["opts"]' v-bind:key="obj.id + '-'+i"  :name="needPics.includes(o)?'0':o" >
                  {{o}}
                </van-radio>
              </van-radio-group>
            </div>
            <div class="item-check" v-if="formData[obj.id].type=='checkbox'">
              <van-checkbox-group direction="horizontal" v-model="formData[obj.id]['value']">
                <van-checkbox shape="square" v-for='(o,i) in tableItems[obj.id]["opts"]' disabled v-bind:key="obj.id + '-'+i"  :name="o.trim()">{{o}}</van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="item-check" v-if="formData[obj.id].type=='textarea'" style="color: #c8c9cc;">
              {{formData[obj.id]['des']?formData[obj.id]['des']:formData[obj.id]['value']}}
            </div>

          </div>
          <div v-if="formData[obj.id]['value']=='0'|| (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length>1) || (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length==1 && formData[obj.id]['value'][0] !='没有问题')">
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div v-if="fileList[obj.id] && fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"   />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
          <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'|| (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length>1) || (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length==1 && formData[obj.id]['value'][0] !='没有问题')">
            <div class="rectify-title">整改情况

            </div>
            <div v-if="isRectify">
              <van-form :label-width="labelWidth" :ref="'rectifyForm'+ obj.id"  @submit="onSubmit(obj.id)">
                <van-field name="remark" rows="3"
                           :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                           v-model="rectify[obj.id]['des']"
                           autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
                <div class="form-label"><span></span>上传照片</div>
                <van-field name="uploader" label=""  :rules="[{ required:true,message: '至少上传一张照片',ruleId:obj.id  }]">
                  <span class="label-tips">*</span>
                  <template #input>
                    <van-uploader  capture="camera"  v-model="rectify[obj.id]['pics']"  :before-read="beforeRead"  :max-count="6" />
                  </template>
                </van-field>
                <div class="item-btn">
                  <van-button plain block type="default" :loading="loading"  native-type="submit">提交</van-button>
                </div>
              </van-form>
            </div>

            <div v-else>
              <van-field name="remark" rows="3"
                         :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                         v-model="rectify[obj.id]['des']"
                         autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
              <div class="form-label"><span></span>整改照片</div>
              <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
                <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
              </div>
              <div v-else style="padding: 8px 16px 0px;">
                无
              </div>
            </div>
          </div>
        </div>

      </div>
       </div>

    <div v-if="customerShow">
      <div class="form-title" >根据机位设置情况抽查实名登记上网执行情况</div>
      <div  v-for="(c,i) in customer" v-bind:key="'customer'+i">
        <div class="form-title">用户{{i+1}}</div>
        <div v-for="(o,m) in c" v-bind:key="'customer-o'+m">
          <div v-if="o.type=='textarea'" class="item-list" >
            <div class="item-title">
              {{  o.name}}
              <span class="item-check-des" style="position: absolute;right: 24px;">
                {{o.des?o.des:o.value}}
            </span>
            </div>
          </div>
          <div class="item-list" v-else>
            <div class="item-title">
              {{   o.name }}
              <span style="position:absolute;right: 0px;">
              <van-radio-group v-model="o.value" direction="horizontal">
              <van-radio name="1" icon-size="24px">是</van-radio>
              <van-radio name="0" icon-size="24px">否</van-radio>
            </van-radio-group>
            </span>
            </div>

          </div>
        </div>
        <van-divider/>
      </div>
      <div v-for="(obj,k,index) in customerRectify" v-bind:key="'customerRectify'+index">
        <div class="rectify-content">
          <div class="rectify-title">整改情况</div>
          <div v-if="isRectify">
            <van-form :label-width="labelWidth" :ref="'rectifyForm'+ obj.id"  @submit="onSubmit(obj.typeId)">
              <van-field name="remark" rows="3"
                         :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                         v-model="customerRectify[k]['des']"
                         autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
              <div class="form-label"><span></span>上传照片</div>
              <van-field name="uploader" label=""  :rules="[{ required:true,message: '至少上传一张照片',ruleId:obj.id  }]">
                <span class="label-tips">*</span>
                <template #input>
                  <van-uploader  capture="camera"  v-model="customerRectify[k]['pics']"  :before-read="beforeRead"  :max-count="6" />
                </template>
              </van-field>
              <div class="item-btn">
                <van-button plain block type="default" :loading="loading"  native-type="submit">提交</van-button>
              </div>
            </van-form>
          </div>
          <div v-else>
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="customerRectify[k]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="customerRectify[k]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="customerRectify[k]['pics']"  :max-count="customerRectify[k]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>

      </div>
    </div>


    <div style="padding-bottom:10px;padding-top: 16px;"  v-if="isRectify">
      <van-row  type="flex" justify="center"  gutter="20">
        <van-col span="24">
          <van-button  size="large"  type="info"  :loading="loading" @click="changeStatus">完成</van-button>
          <!--            <van-button plain hairline round size="large" type="info" :loading="loading" native-type="submit">提交</van-button>-->
        </van-col>
      </van-row>


    </div>

  </div>
</template>
<script>
import Compressor from 'compressorjs';
import {
  Toast,Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup,Divider,Checkbox, CheckboxGroup
} from 'vant';
export default {
  data(){
    return {
      labelWidth:window.innerWidth - 165,
      group:{},
      formDict1:{},
      formDict2:{},
      formDict3:{},
      formData:{},
      check:{},
      fileList:{},
      rectify:{},
      person:"",
      loading:false,
      isRectify:true,
      tableItems:{},
      customer:[],
      customerShow:false,
      customerRectify:{},
      needPics:GlobalConfig.needPhotos
    }
  },
  components:{
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Divider.name]: Divider
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    },
    dict:{
      type:Array,
      default: ()=>{
        return []
      }
    },
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        console.log(this.group,"group",this.dict)
        this.customer = [];
        this.customerShow = false;
        this.group = {};
        this.customerRectify = {};
        this.renderDict();
        if(n){
          if(n.status == '1'){
            this.isRectify = true;
          }else{
            this.isRectify = false;
          }
          this.formData = JSON.parse(n.content);
          let proFiles = {};
          for(let k in this.formData){
            if(this.formData[k].fileList){
              if(!proFiles[k]){
                proFiles[k] = [];
              }
              proFiles[k] = this.formData[k].fileList.split(",").map(url=>{
                return {
                  url:url,
                  isImage: true
                }
              });
            }
          }
          console.log(proFiles,"proFiles")
          this.rectify = {};
          this.fileList = {};

          let arr = [];
          let cusArr = [];
          for(let k in this.formData){
            if(this.tableItems[k] && this.tableItems[k].groupName=='根据机位设置情况抽查实名登记上网执行情况'){
              arr.push({
                ...this.formData[k],
                id:k
              });
            }else if(k=='customer'){
              console.log(this.formData['customer'].length)
              if(this.formData['customer'] && this.formData['customer'].length>0){
                cusArr = this.formData['customer'];
              }

            }
          }
          if(arr.length>0){
            this.customer.push(arr);
            this.customerShow = true;
          }
          if(cusArr.length>0){
            this.customer = this.customer.concat(cusArr)
            this.customerShow = true;
          }


          n.problemList.map(problem=>{

            let pics = [];
            let rectifyPics=[];
            if(problem.rectifyFileList){
              problem.rectifyFileList.map(pic=>{
                rectifyPics.push({
                  url:pic,
                  isImage: true
                })
              })
            }

            if(this.tableItems[problem.typeId].groupName!='根据机位设置情况抽查实名登记上网执行情况'){
              this.$set(this.rectify,problem.typeId,{
                des:problem.rectifyResult,
                id:problem.id,
                pics:rectifyPics,
                save:rectifyPics.length>0?true:false,
                checkResult:problem.checkResult,
                createTime:problem.createTime,
                typeId:problem.typeId
              })
              if(problem.recordFileList){
                problem.recordFileList.map(pic=>{
                  pics.push({
                    url:pic,
                    isImage: true
                  })
                })
              }
              if(proFiles[problem.typeId]){
                this.$set(this.fileList,problem.typeId,pics.concat(proFiles[problem.typeId]));
              }else{
                this.$set(this.fileList,problem.typeId,pics);
              }
              // this.$set(this.fileList,problem.typeId,pics);
            }else{
              this.$set(this.customerRectify,problem.typeId,{
                des:problem.rectifyResult,
                id:problem.id,
                pics:rectifyPics,
                save:rectifyPics.length>0?true:false,
                checkResult:problem.checkResult,
                createTime:problem.createTime,
                typeId:problem.typeId
              })
            }


          })

        }
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
    console.log("this.dict",this.dict);
  },
  methods:{
    renderDict(){
      if(this.dict){
        this.dict.map((item,index)=>{
          if(!this.tableItems[item.id]){
            if(item.type=='radio1'||item.type=='checkbox'){
              item.opts = item.options?item.options.split(";"):[];
            }
            this.tableItems[item.id] = item;
          }
          if(!item.groupName){
            item.groupName = "其他";
          }
          if(!this.group[item.groupName]){
            this.group[item.groupName] = [];
          }
          this.group[item.groupName].push(item);
        })
      }
    },
    validatorPic(val,rule){
      return (Array.isArray(val)&&val.length>0)?true:false;
    },
    onSubmit(id){
      let fileList = [];
      console.log(this.rectify[id],"lkskjslkd");
      let problem = {};
      if(this.rectify[id]){
        this.rectify[id].pics.map((pic,index)=>{
          if(pic.file){
            fileList.push({
              name: pic.file.name.replace(/image/,new Date().getTime() + id + "-"+  index ),
              content:pic.content
            })
          }
        })
        problem = {
          id:this.rectify[id].id,
          checkResult:this.rectify[id].checkResult,
          typeId:this.rectify[id].typeId,
          createTime:this.rectify[id].createTime,
          rectifyResult:this.rectify[id].des,
          fileList:fileList,
          recordId:this.recordData.id
        };
      }else if(this.customerRectify[id]){
        this.customerRectify[id].pics.map((pic,index)=>{
          if(pic.file){
            fileList.push({
              name: pic.file.name.replace(/image/,new Date().getTime() + id + "-"+  index ),
              content:pic.content
            })
          }
        })
        problem = {
          id:this.customerRectify[id].id,
          checkResult:this.customerRectify[id].checkResult,
          typeId:this.customerRectify[id].typeId,
          createTime:this.customerRectify[id].createTime,
          rectifyResult:this.customerRectify[id].des,
          fileList:fileList,
          recordId:this.recordData.id
        };
      }


      this.loading = true;
      this.$http({
        url: "/zuul/cultural/check/problem/",
        method: "post",
        data:problem
      }).then(res=>{
        if(res.data.success){
          this.rectify[id].save = true;
          Notify({ type: 'success', message: '提交成功' });
        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
        this.loading = false;
      })
    },

    changeStatus(){
      console.log(this.recordData,"changeStatus")
      let validatorAll = true;
      for(let k in this.rectify){
        if(!this.rectify[k].save){
          validatorAll = false;
          break;
        }
      }
      for(let k in this.customerRectify){
        if(!this.customerRectify[k].save){
          validatorAll = false;
          break;
        }
      }
      if(!validatorAll){
        Notify({ type: 'warning', message: '未完全整改，不能结束！' });
        return false;
      }
      let problems = [];
      this.recordData.problemList.map(item=>{
        let p = {
          checkResult: item.checkResult,
          createTime:  item.createTime,
          id:  item.id,
          recordId:  item.recordId,
          rectifyResult: this.rectify[item.typeId]?this.rectify[item.typeId].des:this.customerRectify[item.typeId].des,
          typeId: item.typeId
        }
        problems.push(p)
      })
      this.loading = true;
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:{
          content:this.recordData.content,
          createTime: this.recordData.createTime,
          id: this.recordData.id,
          infoId: this.recordData.infoId,
          person: this.recordData.person,
          problemList:problems,
          rectify: this.recordData.rectify,
          remark: this.recordData.remark,
          status: 2,
          type: this.recordData.type,
        }
      }).then(res=>{
        if(res.data.success){
          Notify({ type: 'success', message: '已提交' });
          this.isRectify = false;
        }
      }).finally(e=>{
        this.loading = false;
      })
    },
    beforeRead(file,defaultQuqlity=0.6) {
      let fileSize = file.size/1024/1024;
      let quality = defaultQuqlity;
      if(fileSize<0.5){
        quality = 1;
      }else if(fileSize<1){
        quality = 0.8
      }else if(fileSize<2){
        quality = 0.6
      }else if(fileSize<4){
        quality = 0.3   // 0.4 0.24
      }else if(fileSize<6){
        quality = 0.15  // 0.1 0.06   0.2-0.17
      }else if(fileSize<8.1){
        quality = 0.1
      }else{
        return new Promise((resolve,reject)=>{
          Toast('文件大小不能超过 8M');
          reject({
            msg:"图片不能超过6M"
          })

        })
      }
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,
          success(result){
            console.log(result.size/1024/1024,"111")
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .van-radio-group--horizontal{
  width: auto !important;
}
/deep/ .van-radio--horizontal{
  margin-bottom: 6px;
}

/deep/ .van-checkbox--horizontal{
  margin-bottom: 8px;
}
.detail{
  padding-bottom: 0px;
  background: #f4f6fa;
}
.section-title{
  margin: 0;
  padding: 8px 16px 0px;
  color: #f8a900;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.form-title{
  //color: rgba(69, 90, 100, 0.6);
  font-size: 1.1rem;
  color:#4E555B;
  line-height: 40px;
  padding: 5px 16px 0px;
}
.type-title{

  margin: 0px 16px 20px;
  //border: 1px solid rgba(237, 106, 12,0.5);
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100);
}
.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.item-list{
  padding: 0px 16px;
}
.item{
  position: relative;
  margin: 0px 0px 2px;
}
.hasRectify{
  margin-bottom: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100);
}
.item-title{
  //color: #646566;
  color:#1E6CF7;
  padding: 10px 0px;
  font-size:1rem;
  border-bottom: 1px solid #eee;
  position: relative;

  .item-index{
    position: absolute;
    left: 2px;
    top: 8px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: #40a9ff;
    text-align: center;
    color: white;
    border-radius: 50%;
  }

  .item-name{
    display: inline-block;
    position: relative;
    top: -10px;
    padding-left: 30px;
    width: calc(100vw - 50px);
  }
  .item-check{
    padding: 5px 12px;
  }
}
.item-check-des{
  padding:5px 10px;
  color: #F7671E;
  font-size:1rem;

}
.rectify-content{
  //border: 1px solid rgba(0,0,0,0.1);

  .item-btn{
    padding: 20px 20px;
  }
}
.rectify-title{
  line-height: 40px;
  padding: 0px 10px;

}
/deep/ .van-radio-group--horizontal{
  width: 110px;
}
/deep/ .van-field__body textarea{
  background: rgba(244, 246, 250, 100);
  padding: 10px;
}
/deep/ .van-radio--horizontal{
  margin-right: 5px;
}
</style>
